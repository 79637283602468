.edit-password-container {
  max-width: 420px;
  margin: 0 auto;
  margin: 0 auto;
  margin-top: 40px;
}

.edit-password-button {
  width: 100%;
}
