.account {
  margin-top: 16px;
  text-align: center;
}

.margin0 {
  margin: 0;
}

.textRight {
  text-align: right;
}

.mt32 {
  padding-top: 32px;
}
