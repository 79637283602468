.forgotpassword-container {
  max-width: 420px;
  margin: 0 auto;
  margin: 0 auto;
  margin-top: 40px;
}

.forgotpassword-form-button {
  width: 100%;
}

.thread-container {
  padding: 32px 0;
}

.thread-wrapper {
  margin-top: 8px;
}

.title {
  font-size: 18px;
  word-wrap: break-word;
}

.username {
  font-size: 12px;
}

.no-of-comments {
  font-size: 20px;
}

.replies-label {
  font-size: 12px;
}

.no-questions-found-placeholder {
  text-align: center;
  margin: 128px 0;
}
