.forgotpassword-container {
  max-width: 420px;
  margin: 0 auto;
  margin: 0 auto;
  margin-top: 40px;
}

.forgotpassword-form-button {
  width: 100%;
}
